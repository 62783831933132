import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import tw from "twin.macro"

const Article = tw.article`transition duration-500 flex flex-col rounded-lg shadow-lg hover:shadow-2xl overflow-hidden h-full`

export default function ArticleCard({
  title,
  post,
  featuredImgFluid,
  first,
  imageAlt,
}) {
  return (
    <Article itemScope itemType="http://schema.org/Article" className="group">
      <div tw="flex-shrink-0">
        {featuredImgFluid &&
          (first ? (
            <GatsbyImage
              alt={imageAlt}
              image={featuredImgFluid}
              imgStyle={{height: "30rem",}}
              tw="transition duration-500 ease group-hover:transform group-hover:scale-105 h-48 lg:h-96"
            />
          ) : (
            <GatsbyImage
              alt={imageAlt}
              image={featuredImgFluid}
              tw="transition duration-500 ease group-hover:transform group-hover:scale-105 h-48"
            />
          ))}
      </div>
      <div tw="flex-1 bg-white p-6 flex flex-col justify-between max-w-screen-sm">
        <div tw="flex-1">
          <header>
            <h2 tw="text-xl font-semibold text-gray-900">
              <span itemProp="headline">{title}</span>
            </h2>
          </header>
          <section>
            <p
              tw="mt-3 text-base text-gray-500"
              dangerouslySetInnerHTML={{
                __html: post,
              }}
              itemProp="description"
            />
          </section>
        </div>
        <ReadMore />
      </div>
    </Article>
  )
}

function ReadMore() {
  return (
    <div tw="mt-2">
      <span tw="font-condensed uppercase transition duration-500 ease-in-out text-system-grey-900 group-hover:font-bold">
        Read more&nbsp; <span>&#8594;</span>
      </span>
    </div>
  )
}

ArticleCard.propTypes = {
  title: PropTypes.string,
  post: PropTypes.string,
  featuredImgFluid: PropTypes.object,
  first: PropTypes.bool,
}
