import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ArticleCard from "../components/article-card"
import Hero from "../components/hero"
import tw from "twin.macro"

const Posts = tw.ol`mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none list-none`
const Wrapper = tw.div`m-auto px-5 max-w-screen-lg mt--32 pb-32`

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || ``
  const siteDescription = data.site.siteMetadata?.description || ``
  const posts = data.allMdx.nodes
  const coverImageUrl = data.site.siteMetadata?.siteHost + data.site.pathPrefix + "/solvi-cover.png"
  
  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <SEO title={siteTitle} image={coverImageUrl} />
        <Bio />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }
  
  return (
    <Layout location={location} title={siteTitle}>
      <SEO 
        title={siteDescription}
        description={siteDescription}
        image={coverImageUrl} />
      <Hero
        title="Solvi Blog"
        subtitle={siteDescription}
      />
      <Wrapper>
        <Posts>
          {posts.map((post, index) => {
            const title = post.frontmatter.title || post.fields.slug
            const featuredImgFluid =
              post.frontmatter?.featuredImage?.childImageSharp
                ?.gatsbyImageData || null
            const currentPost = post.frontmatter.description || post.excerpt
            const imageAlt = post.frontmatter?.featuredImageAlt

            return (
              <li key={post.fields.slug} tw="first:col-span-full">
                <Link to={post.fields.slug} itemProp="url">
                  <ArticleCard
                    post={currentPost}
                    first={index === 0}
                    title={title}
                    featuredImgFluid={featuredImgFluid}
                    imageAlt={imageAlt}
                  />
                </Link>
              </li>
            )
          })}
        </Posts>
      </Wrapper>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  {
    site {
      pathPrefix
      siteMetadata {
        title
        description
        siteHost
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt(pruneLength: 280)
        fields {
          slug
        }
        frontmatter {
          title
          lastUpdated
          description
          featuredImageAlt
          featuredImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`
