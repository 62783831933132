import React from "react"
import tw from "twin.macro"
import { LeadMagnet } from "../components/lead-magnet"

const Paragraph = tw.p`italic`

export default function Bio() {
  return (
    <div>
      <LeadMagnet
        title="Download The Complete UAV Handbook for Agricultural Research"
        subtitle="Learn how to establish a successful drone imaging program without a data scientist"
        actionText="Get your free pdf"
        href="https://learn.solvi.ag/complete-uav-handbook-for-agricultural-research?utm_source=solvi-blog"
        image="complete-uav-handbook.png"
        alt="UAV Handbook"
      />

      <Paragraph>
        Solvi is leading the way in drone analytics for farmers, agronomists,
        and plant breeders who want actionable insights from their data.{" "}
        <a href={`${process.env.APP_DOMAIN}/features`}>
          Learn more about Solvi’s complete solution for drone-based crop
          monitoring
        </a>{" "}
        and{" "}
        <a href={`${process.env.APP_DOMAIN}/signup`}>
          sign up for a free trial
        </a>
        .
      </Paragraph>
    </div>
  )
}
