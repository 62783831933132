import React from "react"
import PropTypes from "prop-types"
import tw, { styled } from "twin.macro"

import background from "../../static/solvi-half-circle.svg"

const Section = styled.section(() => [
  `background-image: url(${background});`,
  tw`bg-right-top text-system-grey-600 bg-no-repeat bg-contain`,
])

const Wrapper = tw.div`m-auto max-w-4xl sm:py-32 py-20 px-5 flex flex-col items-center`

export default function Hero({ actionLink, actionText, title, subtitle }) {
  return (
    <Section>
      <Wrapper>
        <h1 tw="p-0 m-0 animate-fadein text-center font-bold mb-4 text-4xl md:text-6xl lg:leading-hero">
          {title}
        </h1>
        <p tw="animate-fadein text-center text-xl mb-6 leading-8 max-w-2xl">
          {subtitle}
        </p>
        {actionLink && (
          <a
            tw="block w-full lg:max-w-xs tracking-wider text-white leading-6 text-center py-5 px-2 rounded text-xl text-sm shadow uppercase duration-100 ease-linear bg-secondary font-condensed font-bold hover:bg-secondary-hover hover:text-white hover:no-underline focus:text-white focus:no-underline "
            href={actionLink}
          >
            {actionText}
          </a>
        )}
      </Wrapper>
    </Section>
  )
}

Hero.propTypes = {
  actionLink: PropTypes.string,
  actionText: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
}
